import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Img from "gatsby-image"
import styled from "styled-components"
import Layout from '../components/Layout'
import PostList from '../components/PostList'
import Pagination from '../components/Pagination'


export default class IndexPage extends React.Component {
  render() {
    const { data, pageContext } = this.props
    const { edges: posts } = data.allWordpressPost
    const { fluid } = data.wordpressWpMedia.localFile.childImageSharp

    const Hero = styled.div`
      position: relative;
      height: 0;
      top: 50px;
      padding-bottom: 30%;
      margin-bottom: 50px;

      &:before {
        content: '';
        width: 100%;
        height: 150px;
        position: absolute;
        bottom: 0;
        background: linear-gradient(0deg, rgba(0,0,0,0.7), transparent);
      }
    `

    const Image = styled(Img)`
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      z-index: -1;
    `

    const Heading = styled.h1`
      color: #fff !important;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      @media(min-width: 850px) {
        top: 80%;
      }
    `

    return (
      <Layout>
        <div>
          <Hero>
            <Image fluid={fluid} style={{position: `absolute`}} />
            <Heading className="title is-size-1">Blog</Heading>
          </Hero>
        </div>
        <PostList posts={posts} title="Recent Posts" />
        <Pagination pageContext={pageContext} pathPrefix="/" />
      </Layout>
    )
  }
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    allWordpressPost: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number,
    numPages: PropTypes.number,
  }),
}

export const pageQuery = graphql`
  query IndexQuery($limit: Int!, $skip: Int!) {
    allWordpressPost(
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...PostListFields
        }
      }
    }
    wordpressWpMedia(slug: {eq: "sliced-brisket"}) {
      localFile {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`
